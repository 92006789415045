.App {
  text-align: center;
}

.App-header {
  background-image: url("/src/Images/achtergrond.jpg");
  background-size: 100%;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.banner {
  width: 100%;
  height: 150px;
  position: fixed;
  top: 0;
  left: 0;
}

.titel {
  width: 100%;
  margin-left: 150px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.logo {
  width: 140px;
  height: 140px;
  margin-top: 5px;
  margin-left: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.label {
  width: 140px;
  height: 140px;
  margin-top: 5px;
  margin-right: 5px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
}

.footer {
  background-color: #343a40;
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 0;
  padding-top: 7.5px;
}

.link {
  margin-left: 10px;
}

.bestuursoverzicht {
  margin-top: 250px;
  margin-bottom: 50px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bestuurslid {
  margin: 15px;
  padding: 15px;
  background-color: white;
  color: black;
  border-radius: 25px;
  border-style: solid;
  border-color: black;
  font-size: medium;
}

.bestuurslid p {
  margin-top: 15px;
  margin-bottom: 0;
}

.bestuurslid2 {
  width: 200px;
  margin: 15px;
  padding: 15px;
  background-color: white;
  color: black;
  border-radius: 25px;
  border-style: solid;
  border-color: black;
  font-size: medium;
}

.bestuurslid2 p {
  margin-top: 213px;
  margin-bottom: 0;
}

.sponsoroverzicht {
  margin-top: 250px;
  margin-bottom: 50px;
}

.sponsoroverzicht img {
  margin: 15px;
  width: auto;
  max-height: 200px;
  border: 3px solid black;
  background-color: white;
}

.sponsorintro {
  font-size: xx-large;
  font-weight: bold;
}

.sponsor {
  font-size: x-large;
  font-weight: bold;
}

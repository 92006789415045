.ploegoverzicht {
  margin-top: 250px;
  margin-bottom: 50px;
  width: 100%;
}

.ploegoverzicht a {
  color: white;
}

.team {
  max-width: 80%;
  height: auto;
  border: 2px solid black;
  margin-bottom: 50px;
}

.klassement {
  width: 80%;
  height: 800px;
}

.positie {
  font-size: xx-large;
  font-weight: bold;
  margin-top: 20px;
}

.positieoverzicht {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.speler {
  margin: 15px;
  padding: 15px;
  background-color: white;
  color: black;
  border-radius: 25px;
  border-style: solid;
  border-color: black;
  font-size: medium;
}

.speler img {
  height: auto;
  max-width: 250px;
}

.speler p {
  margin-top: 15px;
  margin-bottom: 0;
}

.welkom {
  margin-top: 250px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.intro {
  width: 100%;
  padding: 15px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.7)
  );
  color: black;
  border-radius: 25px;
  border-style: solid;
  border-color: black;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: large;
}

.bold {
  font-size: xx-large;
  font-weight: bold;
}

.nieuws {
  margin-top: 25px;
  margin-bottom: 50px;
  width: 50%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.nieuwsitem {
  margin-top: 25px;
  padding: 15px;
  font-size: medium;
  background-color: white;
  color: black;
  border-radius: 25px;
  border-style: solid;
  border-color: black;
}

.nieuwsitem img {
  width: 50%;
  float: left;
  margin-right: 10px;
}

.nieuwstitel {
  text-align: left;
}

.nieuwstekst {
  text-align: justify;
}

.nieuwslink {
  margin-left: 5px;
  color: black;
}

.linkdiv {
  font-weight: bold;
}

.formulierknop {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.formulierknop:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.formulierknop:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.formulierknop:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.contact {
  margin-top: 250px;
  margin-bottom: 50px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.map {
  height: 618px;
  width: 100%;
}

.contactitem {
  margin: 10px;
  width: 48%;
}

.communicatie p {
  text-align: left;
  padding: 15px;
  font-size: medium;
  background-color: white;
  color: black;
  border-radius: 10px;
  border-style: solid;
  border-color: black;
}

.icon {
  height: 20px;
  margin-right: 10px;
}

.navbar {
  margin-top: 150px;
}

.navtext:hover {
  cursor: pointer;
}

.navspan:hover {
  cursor: pointer;
}

.dropdown-toggle:hover {
  cursor: pointer;
}

.nav-link {
  color: lightgrey;
  font-size: large;
}

.nav-link:hover {
  color: white;
}

.dropdown {
  color: lightgrey;
  list-style-type: none;
}

.dropdown:hover {
  color: white;
}

.linkdiv:hover {
  cursor: pointer;
}
